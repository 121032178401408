<template>
  <b-row>
    <b-col lg="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col lg="7">
            <h4 id="traffic" class="card-title mb-0">Entregas de Pedidos</h4>
            <div class="small text-muted">Administrar todas las formas de entregas de un pedidos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body>
        <b-tabs pills card>
          <b-tab title="Retiro en Local" active>
            <b-card-text>              
              <b-row class="mb-3">                              
                <b-col lg="12">
                  <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Local de Entregas">
                    <i class="fa fa-plus"></i> Agregar
                  </b-button>
                </b-col>          
              </b-row>
              <b-row>
                <b-col>
                  <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="table.items"
                          :fields="table.fields"
                          v-if="table.items.length">

                      <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                      </template>
                    
                      <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                      </template>

                      <template v-slot:cell(code)="data">
                        {{ data.item.description }}
                      </template>                                            

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                          <b-dropdown-item @click="edit(data.item)">
                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                          </b-dropdown-item>
                          <b-dropdown-item @click="remove(data.item)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-table>
                  <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                </b-col>
              </b-row>              
            </b-card-text>
          </b-tab>
          
          <b-tab title="Tarifario Simple">
            <b-card-text>              
              <b-row class="mb-3">                              
                <b-col lg="12">
                  <b-button @click="addTariffSimple()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Tarifa">
                    <i class="fa fa-plus"></i> Agregar
                  </b-button>
                </b-col>          
              </b-row>
              <b-row>
                <b-col>
                  <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="tableTariffSimple.items"
                          :fields="tableTariffSimple.fields"
                          v-if="tableTariffSimple.items.length">

                      <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                      </template>
                    
                      <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                      </template>

                      <template v-slot:cell(country)="data">
                        <div v-if="data.item.country">
                          <b>{{ data.item.country }}</b>
                        </div>
                        <div v-else>                          
                          <b-badge variant="secondary">DISPONIBLE EN TODO LOS PAISES</b-badge>
                        </div>
                      </template>                      

                      <template v-slot:cell(price)="data">
                        <div v-if="data.item.price>0">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                        </div>
                        <div v-else>
                          <div v-if="data.item.legend">
                            {{data.item.legend}}
                          </div>
                          <div v-else>
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(data.item.price)}}
                          </div>
                        </div>
                      </template>                

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                          <b-dropdown-item @click="editTariffSimple(data.item)">
                            <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                          </b-dropdown-item>
                          <b-dropdown-item @click="removeTariffSimple(data.item)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                  </b-table>
                  <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                </b-col>
              </b-row>              
            </b-card-text>
          </b-tab>

          <b-tab title="Tarifario por Destino">
            <b-card-text>   
              <b-alert show variant="warning">Proximamente</b-alert>  
            </b-card-text>
          </b-tab>

        </b-tabs>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD LOCALES -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>   
          <b-col lg="12">
            <b-form-group label="Descripción">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.description"
                            required
                            placeholder="Ingresar una descripción">
              </b-form-input>
            </b-form-group>                                              
          </b-col>                                                           
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD TARIFARIO -->
      <b-modal v-model="modal.formTariffSimple.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formTariffSimple.title}}
        </div>

        <b-row>
          <b-col lg="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffSimple.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>                                              
          </b-col>  
          <b-col lg="12">
            <b-form-group label="País" 
                          description="Ingrese el país en el cual va a estar disponible (código Alpha-2. Si no se completa esta disponible para todos los paises">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffSimple.country"                            
                            required                            
                            placeholder="Ingresar un país Ej: AR">
              </b-form-input>
            </b-form-group>                                              
          </b-col>             
          <b-col lg="12">
            <b-form-group label="Precio">
              <b-form-input type="number"
                            size="sm"
                            step="0.01"
                            v-model="crud.formTariffSimple.price"
                            required
                            placeholder="Ingresar un precio">
              </b-form-input>
            </b-form-group>                                              
          </b-col>
          <b-col lg="12">
            <b-form-group label="Leyenda" description="Leyenda que se muestra cuando el valor es cero. Sino se carga, no se aplica">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTariffSimple.legend"
                            required
                            placeholder="Ingresar una leyenda">
              </b-form-input>
            </b-form-group>                                              
          </b-col>                                                                                 
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTariffSimple.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTariffSimple()">Guardar</b-button>          
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image'

  export default {
    components: {
      Imagen,
    },         
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudStoreDeliveries',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},            
            {key: 'description', label: 'Descripción', class:"align-middle"},                         
            {key: 'f_action', label:'', class:"align-middle"},
          ],          
        },
        tableTariffSimple : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"}, 
            {key: 'country', label: 'País', class:"align-middle"},                                    
            {key: 'price', label: 'Precio', class:"align-middle"},                        
            {key: 'f_action', label:'', class:"align-middle"},
          ],       
        },        
        crud: {
          form: {
            id: 0,
            name: '',
            description: '',            
          }, 
          formTariffSimple: {
            id: 0,
            name: '',      
            price: 0,           
            country: '',
            legend: '',
          },                   
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formTariffSimple: {
            active: false,
            title: ''
          },          
        },     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
      this.loadTariffSimple()
    },
    methods: {
      load() {
        var result = serviceAPI.obtenerEntregasLocal()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },            
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.description = ''
        
        this.modal.form.title = "Nuevo Local de Entregas"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.description = item.description

        this.modal.form.title = "Editar Local de Entregas"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el local de entrega (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Local de Entrega',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEntregasLocal(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {       
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarEntregasLocal(this.crud.form);
        } else {
          var result = serviceAPI.agregarEntregasLocal(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadTariffSimple() {
        var result = serviceAPI.obtenerEntregasTariffSimple()

        result.then((response) => {
          var data = response.data
          this.tableTariffSimple.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },  
      addTariffSimple() {
        this.crud.formTariffSimple.id = 0
        this.crud.formTariffSimple.name = ''
        this.crud.formTariffSimple.price = 0
        this.crud.formTariffSimple.country = ''
        this.crud.formTariffSimple.legend = ''
        
        this.modal.formTariffSimple.title = "Nueva Tarifa"
        this.modal.formTariffSimple.active = true
      },
      editTariffSimple(item) {
        this.crud.formTariffSimple.id = item.id
        this.crud.formTariffSimple.name = item.name
        this.crud.formTariffSimple.price = item.price
        this.crud.formTariffSimple.country = item.country
        this.crud.formTariffSimple.legend = item.legend

        this.modal.formTariffSimple.title = "Editar Tarifa"
        this.modal.formTariffSimple.active = true
      },
      removeTariffSimple(item) {
        this.crud.formTariffSimple.id = item.id
        this.crud.formTariffSimple.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar la tarifa (' + this.crud.formTariffSimple.id + ') - '+ this.crud.formTariffSimple.name + '?', {
          title: 'Borrar Tarifa',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarEntregasTariffSimple(this.crud.formTariffSimple.id);

            result.then((response) => {      
              this.modal.formTariffSimple.active = false        
              loader.hide()
              this.loadTariffSimple()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveTariffSimple() {       
        if(this.crud.formTariffSimple.country) {
          if(this.crud.formTariffSimple.country.length!=2) {
            this.$awn.alert('El código Alpha-2 no puede tener mas de 2 caracteres.');
            return false
          }
        }

        let loader = this.$loading.show();

        if (this.crud.formTariffSimple.id) {
          var result = serviceAPI.editarEntregasTariffSimple(this.crud.formTariffSimple);
        } else {
          var result = serviceAPI.agregarEntregasTariffSimple(this.crud.formTariffSimple);
        }

        result.then((response) => {
          this.modal.formTariffSimple.active = false
          loader.hide()
          this.loadTariffSimple()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

    }    
  }
</script>